/* jshint scripturl:true */
'use strict'

module.exports = function (shariff) {
  var url = 'javascript:window.print();'

  return {
    name: 'print',
    faPrefix: 'fas',
    faName: 'fa-print',
    popup: false,
    shareText: {
      bg: '',
      cs: 'tlačit',
      da: '',
      de: 'drucken',
      en: 'print',
      es: 'impresión',
      fi: '',
      fr: 'imprimer',
      hr: '',
      hu: '',
      it: 'stampa',
      ja: '',
      ko: '',
      nl: 'afdrukken',
      no: '',
      pl: 'drukuj',
      pt: '',
      ro: '',
      ru: 'Распечатать',
      sk: '',
      sl: '',
      sr: '',
      sv: '',
      tr: '',
      zh: '',
    },
    title: {
      bg: '',
      cs: 'tlačit',
      da: '',
      de: 'drucken',
      en: 'print',
      es: 'impresión',
      fi: '',
      fr: 'imprimer',
      hr: '',
      hu: '',
      it: 'stampa',
      ja: '',
      ko: '',
      nl: 'afdrukken',
      no: '',
      pl: 'drukuj',
      pt: '',
      ro: '',
      ru: 'Распечатать',
      sk: '',
      sl: '',
      sr: '',
      sv: '',
      tr: '',
      zh: '',
    },
    shareUrl: url,
  }
}
